<template>
  <v-container>
    <base-card>
      <div slot="heading" class="title font-weight-light">Templates</div>
      <v-row justify="end">
        <v-col cols="3">
          <v-select
            v-model="ownerOrganizationId"
            :items="orgOptions"
            :label="$t('templates.ownerOrganization')"
            outline
            clearable
            @change="onOwnerOrganizationSelected"
          />
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="permissionScope"
            :items="permissionScopeOptions"
            :label="$t('templates.permissionScope')"
            outline
            clearable
            @change="onPermissionScopeSelected"
          />
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="includeDisabled"
            :items="includeDisabledOptions"
            :label="$t('templates.includeDisabled')"
            outline
            clearable
            @change="onIncludeDisabledSelected"
          />
        </v-col>
      </v-row>

      <div class="d-flex flex-row-reverse">
        <router-link to="create_template">
          <v-btn color="teal white--text"> New Template </v-btn>
        </router-link>
      </div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" width="5%">ID</th>
              <th class="text-left" width="15%">
                {{ $t('templates.name') }}
              </th>
              <th class="text-left" width="15%">
                {{ $t('templates.ownerOrganization') }}
              </th>
              <th class="text-left" width="10%">
                {{ $t('templates.permissionScope') }}
              </th>
              <th class="text-left" width="10%">
                {{ $t('templates.enabled') }}
              </th>
              <th class="text-left" width="10%">
                {{ $t('templates.platformDatasetType') }}
              </th>
              <th class="text-left" width="30%">
                {{ $t('templates.hostURL') }}
              </th>
              <th class="text-right" width="5%" />
            </tr>
          </thead>

          <tbody>
            <tr v-for="(t, index) in templates" :key="index">
              <td @click="onSelected(t.id)">
                {{ t.id }}
              </td>
              <td @click="onSelected(t.id)">
                {{ t.name }}
              </td>
              <td>{{ t.owner_organization.name }}</td>
              <td>
                <v-chip v-if="t.permission_scope == 1" color="primary"> Public </v-chip>
                <v-chip v-else-if="t.permission_scope == 2" color="warning"> Private </v-chip>
              </td>
              <td>{{ t.enabled }}</td>
              <td>{{ t.platform_dataset_type }}</td>
              <td>{{ t.host_url }}</td>
              <td class="layout">
                <v-icon small class="mr-2" @click="editTemplate(t.id)"> edit </v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </base-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { DEFAULT_PER_PAGE } from '@/constants'
import BaseCard from '../components/common/BaseCard.vue'

export default {
  components: {
    BaseCard,
  },
  data() {
    return {
      params: {
        perPage: DEFAULT_PER_PAGE,
      },
      lastNext: 0,
      ownerOrganizationId: null,
      permissionScope: null,
      includeDisabled: true,
    }
  },

  mounted() {
    this.fetchClients()
    this.fetchWithCondition()
  },

  computed: {
    ...mapState({
      templates: (state) => state.template.templates.data,
      clients: (state) => state.client.clients,
    }),
    orgOptions() {
      return this.clients.map((org) => ({
        text: org.name,
        value: org.id,
      }))
    },
    permissionScopeOptions() {
      return [
        {
          text: 'PUBLIC',
          value: 1,
        },
        {
          text: 'PRIVATE',
          value: 2,
        },
      ]
    },

    includeDisabledOptions() {
      return [
        {
          text: 'true',
          value: true,
        },
        {
          text: 'false',
          value: false,
        },
      ]
    },
  },

  methods: {
    ...mapActions(['fetchTemplates', 'fetchClients']),

    async onSelected(templateId) {
      this.$router.push({ name: 'templateDetail', params: { templateId } })
    },

    onOwnerOrganizationSelected() {
      this.fetchWithCondition()
    },

    onPermissionScopeSelected() {
      this.fetchWithCondition()
    },

    onIncludeDisabledSelected() {
      this.fetchWithCondition()
    },

    fetchWithCondition() {
      const params = {
        owner_organization_id: this.ownerOrganizationId,
        permission_scope: this.permissionScope,
        include_disabled: this.includeDisabled,
      }
      this.fetchTemplates({ params })
    },

    editTemplate(templateId) {
      this.$router.push({
        name: 'templateEdit',
        params: {
          templateId,
        },
      })
    },

    /*
    // TODO: implement pagination
    fetchNextPage() {
      const { next } = this.users.meta;
      this.lastNext = next;
      this.params = { ...this.params, cursor: next };
      this.fetchUsers({ params: this.params });
    },

    fetchPrevPage() {
      const diff = this.lastNext - this.params.perPage;
      this.lastNext = diff;
      this.params = { ...this.params, cursor: diff };
      this.fetchUsers({ params: this.params });
    },
    */
  },
}
</script>
